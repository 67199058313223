import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import NavBar from "components/NavBar";
import Home from "pages";
import LoginPage from "pages/login";
function App() {
  useEffect(() => {
  }, []);

  return (
      <Router>
        <NavBar />
        <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/login" component={LoginPage} />        

        <Redirect from="*" to="/" />
      </Switch>
      </Router>
  );
}

export default App;
