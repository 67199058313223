import { useState, useEffect } from "react";
import styled from "styled-components";
import ForeclosureComp from "components/ForeclosureComp";
import { userContext } from "usercontext";
import { clientLogger } from "utils/clientLogger";

const Home: React.FC = (props) => {

  const [loading, setloading] = useState(true);
  const [showforeclosurecomp, setshowforeclosurecomp] = useState(false);
  const [ user, setUser ] = useState<any>(null)

  const showforeclosure = ()=>{
    setshowforeclosurecomp(!showforeclosurecomp)
  }

  useEffect(() => {
    const subscription = userContext.user.subscribe(x => {
        let user = x; //JSON.parse(JSON.stringify(x))
        setUser(user)
      })

      return () => {
        subscription.unsubscribe()
      }
    }, [])

  useEffect(()=>{
      if(user && user.status==200){
        const msg = `Admin page ${user.email}`
        clientLogger('info', msg)
        setloading(false)
      }
  }, [user])

  return (
    <MainContainer>
        showforeclosurecomp?<ForeclosureComp />:<></>
    </MainContainer>
  )
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 85vh;
  color: #ffffff;
  justify-content: flex-start;
`

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const Column = styled.div`
  padding: 50px;
  display: flex;
  flex-direction: column;
  flex: 1;
  color: #ffffff;
  @media (max-width: 770px) {
    padding: 20px 50px;
  }
`

const Header = styled.div`
    padding: 10px;
    font-weight: 700;
    font-size: 40px;
    justify-content: flex-end;
`;

const SubHeader = styled.div`
    font-weight: 700;
    font-size: 25px;
    justify-content: flex-end;
`;

const SubSubHeader = styled.div`
    font-weight: 600;
    font-size: 18px;
    justify-content: flex-end;
`;

const Extra = styled.div`
  font-weight: 500;
  font-size: 12px;
  justify-content: flex-end;
`;

const SmallButton = styled.div`
  margin: 5px;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  text-transform: uppercase;
  padding: 7px;
  font-weight: 500;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25)
  background-color: #4D2FA2;
  min-width: 50px;
  transition: ease 0.5s;
  &:hover {
    font-weight: 700;
    background-color: #212122;
  }
`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 10px;
`;


export default Home;
