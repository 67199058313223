import { useEffect, useState } from "react";
import styled from "styled-components";
import MessageModal from "components/MessageModal";
import Button from "components/Button";
import { userContext } from 'usercontext';
import InputComp from "components/InputComp";

interface Props{
    history?: any;
}

const LoginPage: React.FC<Props> = (props) => {

  const [user, setUser] = useState<any>(null)
  useEffect(() => {
    const subscription = userContext.user.subscribe((x:any) => setUser(x))
    return () => subscription.unsubscribe()
  }, [])

  useEffect(()=>{
    // console.log(`user ${JSON.stringify(user)}`)
    if(!user)return;
    if(user.status==200){
      props.history.push("/")
    }
  },[user])
  

  const [email, setemail] = useState('')
  const [password, setpassword] = useState('')
  const [modalheader, setmodalheader] = useState('')
  const [modalmessage, setmodalmessage] = useState('')
  const [modalprogress, setmodalprogress] = useState(false)  
  const [modalstate, setmodalstate] = useState(false)
  const [pwdsent, setpwdsent] = useState(false)

  const openModal = (header: string, message: string, progress?: boolean) => {
    setmodalheader(header)
    setmodalmessage(message)
    setmodalstate(true)
    setmodalprogress(progress?progress: false)
    
  }

  const onloginenter = (e: any) =>{
      if(e.key === "Enter"){
        submit()
      }
    }

  const submit = async () => {

    openModal("Authenticating", "", true)

    let sendemail = email.replace(/\s/g, '')

    if(sendemail=="" || password==""){
      openModal("Warning", "Invalid fields.")
      return;
    }
    // console.log(`Here submit`)
    try{
      const user: any = await userContext.login(sendemail, password)
      // console.log(`status ${JSON.stringify(user)}`)
      if(user.data.status==200){
          // router.push("/")
          return;
      }
      openModal("Login failed", "Invalid credentials")
    }catch(error){
      //console.log(`catch login error ${JSON.stringify(error)}`)
      openModal("Login failed", "Invalid credentials")
    }
  }

  const changeemail = (e: any) => {
    setemail(e.target.value.toLowerCase())
    setpwdsent(false)
  }


  return (
      <MainContainer onKeyDown={(e) =>{onloginenter(e)}}>
          <MessageModal header={modalheader} message={modalmessage} state={modalstate} handleClose={()=> setmodalstate(false)} progress={modalprogress} />
          <Column>
          <Header>Login</Header>
          <InputComp id="loginemail" placeholder="email" value={email} onChange={(e) => changeemail(e)} />
          <InputComp id="loginpwd" type="password" placeholder="password" value={password} onChange={(e) => setpassword(e.target.value)} />
          <Button handleClick={()=>submit()} text="Login" />
          </Column>
      </MainContainer>
  )
};

const MainContainer = styled.div`
  display: flex;
  padding: 50px;
  flex-direction: column;
  align-items: center;
  min-height: 75vh;
`
const Column = styled.div`
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 900px) {
	padding: 20px;
  }
`
const Header = styled.div`
    padding: 10px;
    font-weight: 300;
    font-size: 40px;
    justify-content: flex-end;
    color: black;
`;

export default LoginPage;
