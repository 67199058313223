import styled from "styled-components";

export const Top = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const MainContainer = styled.div`
  display: flex;
  margin: 15px;
  justify-content: center;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const ListInfoBlock = styled.div`
  display: flex;
  padding: 25px;
  display: flex;
  flex-direction: column;
  width: 90vw;
  align-self: center;
  justify-self: center;
`;

export const Header = styled.h1`
  font-size: 45px;
  font-weight: 900;
  text-align: center;
  margin: 5px;
`;

export const Paragraph = styled.p`
  line-height: 2;
  font-size: 16px;
  font-weight: 500;
  margin: 35px;
`;

export const BoldText = styled.div`
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  margin: 5px;
`;

export const ExtraText = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin: 5px;
`;

export const SectionTitle = styled.div`
  font-size: 24px;
  font-weight: 900;
  margin: 5px 0;
`;

export const SectionSubTitle = styled.div`
  font-size: 16px;
  font-weight: 900;
  margin: 5px;
`;

export const SideBlock = styled.div`
  flex: 1;
  border-left: 1px solid lightgray;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 770px) {
    border: none;
  }
`;
