import React, { FC, useEffect, useState, useCallback } from 'react'
import { withRouter, useHistory } from "react-router-dom"
import styled, { keyframes } from 'styled-components'
import MenuLink from './MenuLink';
import { AiOutlineMenu } from 'react-icons/ai'
import { userContext } from "usercontext"
import MenuButton from './MenuButton';
import Logo64 from 'assets/logo.png'

interface Props{
  history?: any
}

const Navbar: FC<Props> = props => {
  const [user, setUser] = useState<any>(null)
  const [hidemenu, sethidemenu] = useState(true)
  const [firstname, setfirstname] = useState('')

  const router: any = useHistory()
  const hidemobilemenu = () => {
    if(!hidemenu){
      sethidemenu(true)
    }
  }

  const logout = () => {
    userContext.logout()
  }

  useEffect(() => {
    if(!user)return
      let keys: any = Object.keys(user)
      
      if(keys.length<1 || user.status !=200){
        setfirstname('')
        props.history.push("/login")
      }else{
        setfirstname(user.firstname)
      }
      setUser(user)
  }, [user])


  useEffect(() => {
    const subscription = userContext.user.subscribe(x => {
      let user: any = x; //JSON.parse(JSON.stringify(x))
      let keys: any = Object.keys(user)
      
      if(keys.length<1 || user.status !=200){
        setfirstname('')
        props.history.push("/login")
      }else{
        setfirstname(user.firstname)
      }
      setUser(user)
    })
    return () => subscription.unsubscribe()
  }, [router])

  return (
    <>
      <StyledNavBar onClick={hidemobilemenu}>
        <StyledNavBarInner>
          <Logo>
            <Link href="/" style={{display:'flex', alignItems:'center', justifyContent:'center', fontSize:'50px'}}>
                <Image alt="64cents.com" src={Logo64} width={60} height={60} />
                <LogoText>64cents</LogoText>
            </Link>
          </Logo>
          {!hidemenu?
          <StyledBackdrop>
            <MobileMenuContainer>
              <Image alt="" src={Logo64}width={50} height={50}/>
              <div style={{display: 'flex', justifySelf: 'flex-end', color: "#40bf40"}}>64cents</div>

              <MenuLink key={`menuhome`} text={'home'} link={'/'}></MenuLink>
              <MenuLink key={`menublogs`} link={'/blogs'} text={'Blogs'}></MenuLink>
              {/* <MenuLink key={`menufaq`} link={'/frequently-asked-questions'} text={'FAQ'}></MenuLink> */}
              {/* <MenuLink key={`menusupport`} link={'/blogs'} text={'Blogs'}></MenuLink> */}
              
              {user && user.status==200?
                <>
                  <MenuButton key={`menulogout`} handleClick={logout} text={'Logout'} ></MenuButton>
                  <MenuLink key={`menuname`} text={`Hi ${firstname}`} ></MenuLink>
                </>
                  :
                <>
                  <MenuLink key={`menulogin`} link={'/login'} text={'Login'} ></MenuLink>
                </>
              }
            </MobileMenuContainer>
          </StyledBackdrop>
            :<></>
          }
            <MenuContainer>
              <MenuLink key={`mmenuhome`} text={'home'} link={'/'}></MenuLink>
                  
              {user && user.status==200?
                <>
                  <MenuButton key={`mmenulogout`} text={'Logout'} handleClick={logout}></MenuButton>
                  <MenuLink key={`menuname`} text={`Hi ${firstname}`} ></MenuLink>
                </>:
                <>
                  <MenuLink key={`mmenulogin`} link={'/login'} text={'Login'} ></MenuLink>
                </>
              }
            </MenuContainer>

            <MenuIcon>
              <MenuIcon1 onClick={()=>router.push("/")}  style={{alignItems:'center'}}>
                <Column>
              </Column>
              </MenuIcon1>
              <MenuIcon1 onClick={()=>sethidemenu(!hidemenu)}>
                <AiOutlineMenu size={"35px"}/>
              </MenuIcon1>
            </MenuIcon>
        </StyledNavBarInner>
      </StyledNavBar>
    </>
  )
}

const slideIn = keyframes`
  0% {
    transform: translateX(-100%)
  }
  100% {
    transform: translateX(0)
  }
`

const MenuIcon = styled.div`
  display: none;
  width: 100%;
  @media (max-width: 900px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
`;

const MenuIcon1 = styled.div`
  display: flex;
`;

const MenuContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #000000;
  @media (max-width: 900px) {
    display: none;
  }
`

const MobileMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-text: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: white;
  color: #FFFFFF;
  width: 50%;
  padding: 25px;
  transition: ease 0.5s;
  animation: ${slideIn} 0.3s forwards ease-out;
  `;

const StyledNavBar = styled.div`
  align-items: center;
  color: #000000;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25)
`
const StyledNavBarInner = styled.div`
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
  color: #000000;
`
const StyledBackdrop = styled.div`
  background-color: #000000;
  opacity: 1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
`;

const Logo = styled.div`
  display: flex;
  flex: 2;
  align-items: center;
  justify-content: flex-start;
  @media(max-width:900px){
    // display: none;
  }
`
const LogoText = styled.div`
  display: flex;
  flex: 2;
  color: #000000;
  // align-items: center;
  // justify-content: flex-start;
  padding: 0 10px;
  @media(max-width:900px){
    font-size: 30px;
    // display: none;
  }
`

const Username = styled.div`
  display: flex;
  margin: 0 5px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  color: #33813B;
  border-radius: 3px;
  padding: 5px;
  transition: ease 0.5s;
  font-weight:700;
  @media (max-width: 900px) {
    margin: 5px;
  }&:hover{
    text-decoration: underline;
    // color: #a56fa5;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Item = styled.div`
  font-size: 50px;
  // font-weight: 700;
  margin: 0 0 0 10px;
`;

const Link = styled.a`

`;

const Image = styled.img`
`;

export default withRouter(Navbar)
