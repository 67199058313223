import { userContext } from "usercontext"
import axios from "axios";

function authHeader(url: any) { 
    // return auth header with jwt if user is logged in and request is to the api url
    const user: any = userContext.userValue;
    // if(url.includes("/api/admin/")){
    //     if(user.role!=="admin747"){
    //         console.log(`Unauthorized user not admin`)
    //         return {}
    //     }
    // }
    const isLoggedIn = user && user.token;
    if (isLoggedIn) {
        return { Authorization: `Bearer ${user.token}` };
    } else {
        return {};
    }
  }
  
  export const fetchWrapper = {
      post,
      postFile,
      getFile
  };
  

  function getFile(url: any) {
    const requestOptions: any = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', ...authHeader(url) },
        responseType: 'stream'
    };
    return fetch(url, requestOptions).then(handleFileResponse);
}
  
  function postFile(url: any, body: any) {
    const requestOptions:any = {
        method: 'POST',
        // headers: { 'Content-Type': 'application/json', ...authHeader(url) },
        headers:  authHeader(url) ,
        credentials: 'include',
        body: body,
    };    
    return fetch(url, requestOptions).then(handleResponse)
  }
  
  
  
  function post(url: any, body: any) {
      const requestOptions:any = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', ...authHeader(url) },
          credentials: 'include',
          body: JSON.stringify(body)
      };
      return axios.post(url, JSON.stringify(body), requestOptions)
  }
  
  function handleResponse(response: any) {
      return response.text().then((text: any) => {
          const data = text && JSON.parse(text)
          
          if (!response.ok) {
              if ([401, 403].includes(response.status) && userContext.userValue) {
                  // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                  userContext.logout()
              }
              const error = (data && data.message) || response.statusText;
              return Promise.reject(error)
          }
          return data;
      })
  }

  function handleFileResponse(response: any) {
    return response;
}