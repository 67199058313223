import { formatName, monthNames } from "utils/helperfunctions"

export const titletemplates = (city: any, county: any, templatecategoryid: any, templateid: any) => {
    let dt = new Date()
    const templates = [
        [
            // foreclosure change
            `What's driving the High Foreclosure Rate in ${formatName(city)}?`,
            `Residential Foreclosures in ${city} up/down by x% in ${formatName(monthNames[dt.getMonth()])}`,
            `What homes are under foreclosure in ${city}?`,
        ],
        [
            // mortgage rate change
            `Residential foreclosures down/up in ${formatName(city)} as Mortgage Rates (remain) (low/high)`,
            `The Anticipated Federal Reserves' next move is bringing some relief to homeowners facing foreclosure in ${formatName(city)}`,
            `High/Low Mortgage rates pose difficulty/ease refinancing; Signs visible in high/low foreclosure rates in ${formatName(city)}.`,
        ],
        [
            // construction spending
            `Reduced/Increased Construction Spending Signalling Low/High Margin in Foreclosure Investment.`,
            `Is Residential Foreclosure Investment Profitable in ${formatName(city)}? Cues from Local Construction Spending.`,
            `The Side Effects of Inflation: Reduced Construction Spending and Rising Foreclosures in ${formatName(city)}`,
        ],
        [
            // unemployment rate
            `Are higher foreclosure rates in ${formatName(city)} a harbinger of weakness in the Job Market?`,
            // `Are Job Losses Driving Foreclosures in ${city}`,
            `Unemployment and Foreclosures: A Grim Outlook for the Housing Market in ${county}.`,
            `National Unemployment on the Rise: Can the ${formatName(city)} Housing Market Withstand a Downturn?`,
        ],
    ]
    if(templatecategoryid+1>templates.length)return ``
    if(templateid+1>templates[templatecategoryid].length)return ``
    
    return templates[templatecategoryid][templateid]
}