import React, { useState } from "react";
import styled from "styled-components";
import Button from "components/Button";
import SmallButton from "components/SmallButton";
import { fetchWrapper } from "utils/apihandlers";
var util = require("util"); 

interface Prop{
    text: any;
    smallbutton: any;
    county: any;
    properties: any;
    selectedIdx: any;
    singleprop: any;
    setloadingdeed: (loading: boolean)=>void
    setpropid: (i: any)=>void
    setdeeddownloadprogressmsg: (msg: any) => void
    setoperationslog: (msg: any) => void
    openModal: (header: any, message: any, progress: any) => void
    width?: any;
    backgroundColor?: any;
    setparsedtext:(text: string)=>void
}

const ReadNoticesComp:React.FC<Prop> = ({text, county, properties, selectedIdx, singleprop, setloadingdeed, setpropid, setdeeddownloadprogressmsg, setoperationslog, openModal, width, smallbutton, backgroundColor, setparsedtext}) =>{
    
    const downloadAndReadNotice = async () => {
        if(selectedIdx<0)return
        setparsedtext("")
        let url = "/api/foreclosureapi/parsenotice"
        if(!county){
            openModal("Warning", "Please select county before proceeding.", false)
            return
        }
       
        if(singleprop){
            await downloadandReadSingle(url)
        }else{
            console.log(`reading all  notices`)            
            await downloadandReadMany(url)
        }
    }

    const downloadandReadSingle = async (url: any, noticeurl?: any) => {
        let oplog = ""
        if(properties.length<1){
        openModal("Warning", "no properties to load", false)
        return
        }
        setloadingdeed(true)
        setdeeddownloadprogressmsg("")
        let i = selectedIdx
        try{
            setpropid(i)
            let response: any;

            response = await fetchWrapper.post(url, {foreclosureid:properties[i].foreclosureid, county: county}).then((response: any)=> response).catch((error: any) => {
                oplog = `error downloading notice: ${properties[i].foreclosureid}, error ${error}\n${oplog}`
                setoperationslog(oplog)
            })
            console.log(`response : ${JSON.stringify(response.data)}`)
            setparsedtext(util.inspect(response.data.result, {showHidden: false, depth: null}))
            // setparsedtext(response.data)

        }catch(error){
            setoperationslog(`failed to get notice for ${properties[i].foreclosureid}, error: ${error}\n${oplog}`)

        }
        setloadingdeed(false)
        setpropid(-1)
    }

    const downloadandReadMany = async (url: any) => {
        let oplog = ""
        if(properties.length<1){
        openModal("Warning", "no properties to load", false)
        return
        }
        setloadingdeed(true)
        setdeeddownloadprogressmsg("")

        for(let i=0; i<properties.length; i++){
        // if(properties[i].noticeparsed)
        //     continue
            try{
                // console.log(`reading notice ${i}`)
                setloadingdeed(true)
                setdeeddownloadprogressmsg(`reading notice ${i+1} id: ${properties[i].foreclosureid.substring(0,5)}`)
                setpropid(i)
                let response: any;

                response = await fetchWrapper.post(url, {foreclosureid:properties[i].foreclosureid, county: county}).then((response: any)=> response).catch((error: any) => {
                    oplog = `error downloading notice: ${properties[i].foreclosureid}, error ${error}\n${oplog}`
                    setoperationslog(oplog)
                })
                console.log(`response : ${JSON.stringify(response.data)}`)
            }catch(error){
                setoperationslog(`failed to get notice for ${properties[i].foreclosureid}, error: ${error}\n${oplog}`)

            }
        }
        setloadingdeed(false)
        setpropid(-1)
    }

    return (
        <>
        {smallbutton?
            <SmallButton text={text} handleClick={()=>downloadAndReadNotice()} width={width?width:""} backgroundColor={backgroundColor?backgroundColor:""}/>
            :
            <Button text={text} handleClick={()=>downloadAndReadNotice()} width={width?width:""} backgroundColor={backgroundColor?backgroundColor:""}/>
        }
        </>
    )
}

export default ReadNoticesComp