import React, {useState, useEffect} from 'react'
import styled, { keyframes } from 'styled-components'
import Button from "components/Button";
import SmallButton from "components/SmallButton";
import SelectComp from "components/SelectComp";
import { fetchWrapper } from 'utils/apihandlers';
import HorizontalLine from 'components/horizontalLine/HorizontalLine';
import InputComp from 'components/InputComp';
import TextboxComp from 'components/TextboxComp';
import { monthNames } from 'utils/helperfunctions';
import { getCounty } from './citycountymap';
import { texttemplates } from './texttemplates';
import { titletemplates } from './titletemplates';
import { useHistory } from 'react-router-dom';
interface Prop{
    // history?: any;
    openModal: (header: any, message: any, progress?: any) => void;
    setloading: (z: any)=>void;
}
const LocalNewsComp : React.FC<Prop>=({openModal, setloading})=>{
    const [ countyselectoptionid, setcountyselectoptionid ] = useState(0)
    const [ cityselectoptionid, setcityselectoptionid ] = useState(0)
    const [ newsitems, setnewsitems ] = useState<any>([])
    const [ city, setcity ] = useState("")
    const [ county, setcounty ] = useState("")    
    const [ title, settitle ] = useState("")
    const [ category, setcategory ] = useState("")
    const [ selectedcategory, setselectedcategory ] = useState(-1)
    
    const [ newstext, setnewstext ] = useState("")
    const [ selecteditem, setselecteditem ] = useState(-1)
    const [ selectedtexttemplate, setselectedtexttemplate ] = useState(-1)
    const [ selectedtitletemplate, setselectedtitletemplate ] = useState(-1)
    //////////data fields///////////
    const [ mortgagerate, setmortgagerate ] = useState("")
    const [ unemploymentrate, setunemploymentrate ] = useState("")
    const [ constructionspending, setconstructionspending ] = useState("")
    const [ inflationrate, setinflationrate ] = useState("")
    const [ businessdate, setbusinessdate ] = useState("")
    
    const history = useHistory()

    const citynames = ['-select city-',
        'Plano',
        'Frisco',
        'McKinney',
        'Allen',
        'Dallas',
    ]

    const changecityselectoption = (e: any) => {
        setcityselectoptionid(e.target.value)
        if(e.target.value>0){
            setcity(citynames[e.target.value])
            setcounty(getCounty(citynames[e.target.value]))
            settitle("")
            setcategory("")
            setnewstext("")
        }else{
            clear()
        }
    }

    const loadnews = async () => {
        if(cityselectoptionid<1){
            openModal("Warning", "Select county or city before proceeding")
            return
        }
        let body = {
            city: cityselectoptionid>0?citynames[cityselectoptionid]:"",
            county: getCounty(citynames[cityselectoptionid])
        }
        let response = await fetchWrapper.post("/api/localnewsapi/loadLocalitem", body)
        console.log(`response : ${JSON.stringify(response.data)}`)
        setnewsitems(response.data.items)
    }
    const loadAllnews = async () => {

        let body = {
            city: "",
            county: "",
        }
        let response = await fetchWrapper.post("/api/localnewsapi/loadLocalitem", body)
        console.log(`response : ${JSON.stringify(response.data)}`)
        setnewsitems(response.data.items)
    }
    const additem = async () => {
        try{
            openModal("Adding item", "", true)
            let dt = new Date()
            let body = {
                city: city,
                county: county,
                month: monthNames[dt.getMonth()],
                year: dt.getFullYear(),
                title: title,
                newscategory: category,
                text: newstext,
            }
            let response = await fetchWrapper.post("/api/localnewsapi/saveLocalitem", body)
            console.log(`response.data : ${JSON.stringify(response.data)}`)
            
            openModal("Message", response.data.msg, false)
        }catch(error){
            openModal("Error", `Failed to add item ${error}`, false)
        }

    }

    const updateitem = async () => {
        if(selecteditem<0)return
        try{
            openModal("Updating item", "", true)
            let dt = new Date()
            let body = {
                city: city,
                county: county,
                month: monthNames[dt.getMonth()],
                year: dt.getFullYear(),
                title: title,
                newscategory: category,
                text: newstext,
            }
            let response = await fetchWrapper.post("/api/localnewsapi/saveLocalitem", body)
            openModal("Item Added", "Done", false)
        }catch(error){
            openModal("Error", `Failed to update item ${error}`, false)
        }
    }
    const clear = () => {
        setselecteditem(-1)
        setcity("")
        setcounty("")
        settitle("")
        setcategory("")
        setnewstext("")
    }

    const itemclicked =(idx: any) =>{
        setselecteditem(idx)
        setcity(newsitems[idx].city)
        setcounty(newsitems[idx].county)
        settitle(newsitems[idx].title)
        setcategory(newsitems[idx].newscategory)
        setnewstext(newsitems[idx].text)
    }

    const frchangetemplate = () => {
        setcategory("foreclosure listings")
        setselectedcategory(0)
    }
    const mortgageratetemplate = () => {
        setcategory("mortgage rate")
        setselectedcategory(1)
    }
    const constructiontemplate = () => {
        setcategory("construction spending")
        setselectedcategory(2)
    }
    const unemploymenttemplate = () => {
        setcategory("unemployment rate")
        setselectedcategory(3)
    }

    const selecttexttemplate = (id: any) => {
        setselectedtexttemplate(id)
        setnewstext(texttemplates(city, county)[0])
    }
    const selecttitletemplate = (id: any) => {
        if(cityselectoptionid<1){
            openModal("Warning", "Select city before proceeding")
            return
        }
        if(selectedcategory<0){
            openModal("Warning", "Select news item template before proceeding")
            return
        }
        setselectedtitletemplate(id)
        settitle(titletemplates(city, county, selectedcategory, id))
    }

    const loaddata = async () => {
        openModal("Loading data", "", true)
        let response = await fetchWrapper.post("/api/dataapi/loaddata", {}).then((r)=>r)
        console.log(`response : ${JSON.stringify(response.data)}`)
        
        openModal("Data loaded", "", false)

    }

    const addecodata = async () => {
        if(businessdate=="" ||mortgagerate==""||constructionspending==""||unemploymentrate==""||inflationrate=="")
        {
            openModal("Warning", "Enter all fields before proceeding", false)
            return
        }
        let businessdate_arr = businessdate.split("/")
        let month = Number(businessdate_arr[0])
        let year = businessdate_arr[2]
        let day = Number(businessdate_arr[1])
        if(day!==1){
            openModal("Warning", "Business date should be the first of the month", false)
            return
        }
        let body={
            businessdate: businessdate,
            month:month,
            year:year,
            mortgagerate:mortgagerate,
            constructionspending:constructionspending,
            unemploymentrate:unemploymentrate,
            inflationrate:inflationrate,
        }
        openModal("Adding data", "", true)
        let response = await fetchWrapper.post("/api/dataapi/savedata", body).then(r=>r)
        openModal("Message", response.data.msg, false)
    }

    useEffect(() => {
        setloading(true)
    }, [])

    useEffect(() => {
        let user: any = JSON.parse(localStorage.getItem("user")||'{}')

        if(!user || user.status!=200 || user.role!="admin747"){
            console.log(`history: ${history}`)
            
            history.push("/login")
        }
        setloading(false)
    }, [])

    return(
        <MainContainer style={{width: "100%"}}>
            <Column style={{width: "100%"}}>
                <Column style={{width: "100%"}}>
                <Header>Add local New Update</Header>
                <ButtonContainer>
                   <SelectComp id="cityselectid" optionid={cityselectoptionid} labels={citynames} onChange={changecityselectoption} width={125} placeholder="-select city-"/>
                   {
                    cityselectoptionid>0?getCounty(citynames[cityselectoptionid]):"-county-"
                   }
                </ButtonContainer>                    
                <Header style={{padding: "10px"}}>News Items</Header>
                <ButtonContainer>                    
                    <SmallButton text={"load"} handleClick={loadnews}/>
                    <SmallButton text={"load all"} handleClick={loadAllnews}/>
                </ButtonContainer>
                <Row>
                    <ItemHeader style={{flex: 0.15}}>#</ItemHeader>
                    <ItemHeader style={{flex: 1}}>City</ItemHeader>
                    <ItemHeader style={{flex: 2}}>Title</ItemHeader>
                    <ItemHeader style={{flex: 0.5}}>Date</ItemHeader>
                </Row>
                <div style={{height: "250px", overflowY: 'scroll', width: "100%"}}>
                {
                    newsitems.length>0?
                    newsitems.map((v: any, idx: any)=>(
                        <Row key={`itemr_${idx}`} onClick={()=>itemclicked(idx)} style={{color:selecteditem==idx?"#bf40bf":""}}>
                            <Item style={{flex: 0.15}}>{idx+1}</Item>
                            <Item style={{flex: 1}}>{v.city}</Item>
                            <Item style={{flex: 2}}>{v.title}</Item>
                            <Item style={{flex: 0.5,}}>{v.month.substring(0,3)}/{v.year}</Item>
                        </Row>
                    ))
                    :<>None</>
                }
                </div>
                </Column>
                <HorizontalLine />
                <Row style={{borderBottom: 'none'}}>
                    <Column  style={{flex: 1, width: "100%", alignItems: 'flex-start'}}>
                        <Header style={{padding: "10px"}}>Add/Edit News Item</Header>
                        <Item style={{padding: " 0 10px"}}>Load template</Item>
                        <ButtonContainer>                    
                            <SmallButton text={"FR Change"} handleClick={frchangetemplate} backgroundColor={selectedcategory==0?"#bf40bf":""}/>
                            <SmallButton text={"Mortgage Rate"} handleClick={mortgageratetemplate} backgroundColor={selectedcategory==1?"#bf40bf":""}/>
                            <SmallButton text={"Construction"} handleClick={constructiontemplate} backgroundColor={selectedcategory==2?"#bf40bf":""}/>
                            <SmallButton text={"Unemployment"} handleClick={unemploymenttemplate} backgroundColor={selectedcategory==3?"#bf40bf":""}/>
                        </ButtonContainer>
                        <InputComp id="city_input" key="city_input" value={city} placeholder='city' onChange={(e: any) =>setcity(e.target.value)}  />
                        <InputComp id="county_input" key="county_input" value={county} placeholder='county' onChange={(e: any) =>setcounty(e.target.value)} />
                        <InputComp id="cat_input" key="cat_input" value={category} placeholder='article category' onChange={(e: any) =>setcategory(e.target.value)} />
                        <ItemHeader style={{padding: "0 10px"}}>Title Templates</ItemHeader>
                        <Item>{title}</Item>
                        <ButtonContainer>
                            <SmallButton text="Template 1" handleClick={()=>selecttitletemplate(0)} backgroundColor={selectedtitletemplate==0?"#bf40bf":""}/>
                            <SmallButton text="Template 2" handleClick={()=>selecttitletemplate(1)} backgroundColor={selectedtitletemplate==1?"#bf40bf":""}/>
                            <SmallButton text="Template 3" handleClick={()=>selecttitletemplate(2)} backgroundColor={selectedtitletemplate==2?"#bf40bf":""}/>
                        </ButtonContainer>

                        <InputComp id="title_input" key="title_input" value={title} placeholder='article title' onChange={(e: any) =>settitle(e.target.value)} width={"500"} />

                        <ItemHeader style={{padding: "0 10px"}}>Article Templates</ItemHeader>
                        <ButtonContainer>
                            <SmallButton text="Template 1" handleClick={()=>selecttexttemplate(0)} backgroundColor={selectedtexttemplate==0?"#bf40bf":""}/>
                            <SmallButton text="Template 2" handleClick={()=>selecttexttemplate(1)} backgroundColor={selectedtexttemplate==1?"#bf40bf":""}/>
                            <SmallButton text="Template 3" handleClick={()=>selecttexttemplate(2)} backgroundColor={selectedtexttemplate==2?"#bf40bf":""}/>
                        </ButtonContainer>

                        <TextboxComp id="tb_text" value={newstext} onChange={(e: any)=>setnewstext(e.target.value)} rows={10} cols={50} placeholder='Text' width={500} height={350}/>
                        <ButtonContainer>
                            <Button text='Add' handleClick={additem} />
                            <Button text='Update' handleClick={updateitem} />
                            <Button text='Clear' handleClick={clear} />
                        </ButtonContainer>
                    </Column>
                    <Column  style={{flex: 1, width: "100%", height: "100%", alignItems: 'flex-start', justifyContent: 'flex-start', borderLeft: '1px solid black'}}>
                        <Header style={{padding: "10px"}}>Load Data</Header>
                        <Button text='Load data' handleClick={loaddata} />
                        <Row>
                            <ItemHeader2 style={{flex: 1}}>Mtg rate</ItemHeader2>
                            <ItemHeader2 style={{flex: 1}}>1m &Delta;</ItemHeader2>
                            <ItemHeader2 style={{flex: 1}}>3m &Delta;</ItemHeader2>
                            <ItemHeader2 style={{flex: 1}}>6m &Delta;</ItemHeader2>
                            <ItemHeader2 style={{flex: 1}}>1y &Delta;</ItemHeader2>
                        </Row>
                        <Row>
                            <Item style={{flex: 1}}>1.5%</Item>
                            <Item style={{flex: 1}}>1.5%</Item>
                            <Item style={{flex: 1}}>1.5%</Item>
                            <Item style={{flex: 1}}>1.5%</Item>
                            <Item style={{flex: 1}}>1.5%</Item>
                        </Row>
                        <br/>
                        <Row>
                            <ItemHeader2 style={{flex: 1}}>Jobless rate</ItemHeader2>
                            <ItemHeader2 style={{flex: 1}}>1m &Delta;</ItemHeader2>
                            <ItemHeader2 style={{flex: 1}}>3m &Delta;</ItemHeader2>
                            <ItemHeader2 style={{flex: 1}}>6m &Delta;</ItemHeader2>
                            <ItemHeader2 style={{flex: 1}}>1y &Delta;</ItemHeader2>
                        </Row>
                        <Row>
                            <Item style={{flex: 1}}>1.5%</Item>
                            <Item style={{flex: 1}}>1.5%</Item>
                            <Item style={{flex: 1}}>1.5%</Item>
                            <Item style={{flex: 1}}>1.5%</Item>
                            <Item style={{flex: 1}}>1.5%</Item>
                        </Row>
                        <br/>
                        <Row>
                            <ItemHeader2 style={{flex: 1}}>Construction <br/>Spending</ItemHeader2>
                            <ItemHeader2 style={{flex: 1}}>1m &Delta;</ItemHeader2>
                            <ItemHeader2 style={{flex: 1}}>3m &Delta;</ItemHeader2>
                            <ItemHeader2 style={{flex: 1}}>6m &Delta;</ItemHeader2>
                            <ItemHeader2 style={{flex: 1}}>1y &Delta;</ItemHeader2>
                        </Row>
                        <Row>
                            <Item style={{flex: 1}}>1.5%</Item>
                            <Item style={{flex: 1}}>1.5%</Item>
                            <Item style={{flex: 1}}>1.5%</Item>
                            <Item style={{flex: 1}}>1.5%</Item>
                            <Item style={{flex: 1}}>1.5%</Item>
                        </Row>
                        <br/>
                        <Row>
                            <ItemHeader2 style={{flex: 1}}>Inflation Rate</ItemHeader2>
                            <ItemHeader2 style={{flex: 1}}>1m &Delta;</ItemHeader2>
                            <ItemHeader2 style={{flex: 1}}>3m &Delta;</ItemHeader2>
                            <ItemHeader2 style={{flex: 1}}>6m &Delta;</ItemHeader2>
                            <ItemHeader2 style={{flex: 1}}>1y &Delta;</ItemHeader2>
                        </Row>
                        <Row>
                            <Item style={{flex: 1}}>1.5%</Item>
                            <Item style={{flex: 1}}>1.5%</Item>
                            <Item style={{flex: 1}}>1.5%</Item>
                            <Item style={{flex: 1}}>1.5%</Item>
                            <Item style={{flex: 1}}>1.5%</Item>
                        </Row>
                        <br/>
                        <Row>
                            <ItemHeader2 style={{flex: 1}}>#Foreclosures</ItemHeader2>
                            <ItemHeader2 style={{flex: 1}}>1m &Delta;</ItemHeader2>
                            <ItemHeader2 style={{flex: 1}}>3m &Delta;</ItemHeader2>
                            <ItemHeader2 style={{flex: 1}}>6m &Delta;</ItemHeader2>
                            <ItemHeader2 style={{flex: 1}}>1y &Delta;</ItemHeader2>
                        </Row>
                        <Row>
                            <Item style={{flex: 1}}>1.5%</Item>
                            <Item style={{flex: 1}}>1.5%</Item>
                            <Item style={{flex: 1}}>1.5%</Item>
                            <Item style={{flex: 1}}>1.5%</Item>
                            <Item style={{flex: 1}}>1.5%</Item>
                        </Row>
                        <br/>
                        <ItemHeader>Add Data</ItemHeader>
                        <ButtonContainer>
                            <InputComp placeholder='business date' value={businessdate} onChange={(e: any)=>setbusinessdate(e.target.value)} id='business_dt' width={100}/>
                            <InputComp placeholder='mortgage rate' value={mortgagerate} onChange={(e: any)=>setmortgagerate(e.target.value)} id='mtgage_r' width={100}/>
                            <InputComp placeholder='unemployment rate' value={unemploymentrate} onChange={(e: any)=>setunemploymentrate(e.target.value)} id='jobless_r' width={100}/>
                            <InputComp placeholder='construction spending' value={constructionspending} onChange={(e: any)=>setconstructionspending(e.target.value)} id='construction_r' width={100}/>
                            <InputComp placeholder='inflation rate' value={inflationrate} onChange={(e: any)=>setinflationrate(e.target.value)} id='inflation_r' width={100}/>
                            <Button text="Add Data" handleClick={addecodata} />
                        </ButtonContainer>
                    </Column>
                </Row>
            </Column>
        </MainContainer>
    );
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px;
`;

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 85vh;
    justify-content: flex-start;
    align-items: center;
`
const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px;
`;
const Header = styled.div`
    font-size: 24px;
    font-weight: 900;
`;
const SubHeader = styled.div`
    font-size: 20px;
    font-weight: 600;
`;
const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    justiyfy-content: flex-start;
    align-items: flex-start;
    width: 100%;
    border-bottom: 1px solid gray;
`;


const ItemHeader = styled.div`
    font-size: 20px;
    font-weight: 600;
    padding: 0 10px;
    word-break: break-all;
`;
const ItemHeader2 = styled.div`
    font-size: 16px;
    font-weight: 600;
    padding: 0 10px;
    word-break: break-all;
`;
const Item = styled.div`
    font-size: 16px;
    font-weight: 500;
    padding: 0 10px;
`;

export default LocalNewsComp