import React, { useState, useEffect } from 'react'
import styled, { keyframes } from 'styled-components';
import styles from "./Button.module.css";

interface Buttonprops {
    text: string;
    color?: any;
    handleClick?:()=>void;
    backgroundColor?: any;
    width?:any;
}

const SmallButton: React.FC<Buttonprops> = ({text, color, backgroundColor, handleClick, width}) => {

    const btnclick = (e:any) => {
        handleClick&&handleClick()
    }

    return (
        <MainContainer>
            <Container className={styles.ripple} onClick={btnclick} style={{backgroundColor:backgroundColor?backgroundColor:"#33813B", width:width?width:'', userSelect:'none'}} >
                {text}
            </Container>
        </MainContainer>
    )
}

const MainContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
`;
const Container = styled.div`
    margin: 5px;
    min-height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    text-transform: uppercase;
    padding: 7px;
    font-size: 10px;
    font-weight: 300;
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgba(100, 100, 100, 0.25)
    transition: ease 0.5s;
    color: #FFFFFF;
    overflow: clip;
    &:hover {
        box-shadow: 0 2px 4px 2px rgba(100, 100, 100, 1)
    }
`;


export default SmallButton;