import axios from 'axios'

export const formatName = (name: string) => {
  name = name.toLowerCase()
    if(name.length>1)
    {
        name = name[0].toUpperCase() + name.substring(1)
    }
    else{
        name = name.toUpperCase()
    }
    return name
}

export const formatPhoneInput = (newTxt: string, dashPos: number[]) => {
    newTxt = newTxt.replace(/-/g, "")
    dashPos.forEach((value, indx) => {
      if (newTxt.length >= dashPos[indx]) {
        newTxt =
          newTxt.slice(0, dashPos[indx] - 1) +
          "-" +
          newTxt.slice(dashPos[indx] - 1)
      }
    })
    return newTxt;
  };

export const formatNumberCommaNoDecimals = (amount: any) => {
    if(amount=="")
    {
        return "";
    }
    var formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    })
    return formatter.format(amount)
}

export const formatNumberComma = (amount: any) => {
    var formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 4,
    })
    return formatter.format(amount)
}

export const formatDollar = (amount: any) => {
    if(amount=="")
    {
        return "";
    }
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 4,
    })
    return formatter.format(amount)
}

export const formatDollarDigits = (amount: any, digitsafter: any) => {
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: digitsafter,
        maximumFractionDigits: digitsafter,
    })
    return formatter.format(amount)
}

export const formatDate = (dt: Date, stringformat: string) => {
    let strformat = ''
    for (let i = 0; i < stringformat.length; i++)
        strformat += char2num(dt, stringformat[i])
    return strformat
}

export const char2num = (dt: Date, c: string) => {
    switch (c) {
        case 'Y':
        return dt.getFullYear()
        case 'y':
        let y = dt.getFullYear()
        y = y % 100
        return y
        case 'M':
        let mth = dt.getMonth() + 1
        let sMth = mth.toString()
        if (mth < 10) {
            sMth = '0' + sMth
        }
        return sMth
        case 'd':
        let day = dt.getDate()
        let sDay = day.toString()
        if (day < 10) {
            sDay = '0' + sDay
        }
        return sDay
        case 'h':
        return dt.getHours()
        case 'm':
        return dt.getMinutes()
        case 's':
        return dt.getSeconds()
        case 'f':
        return dt.getMilliseconds()
        default:
        return c
    }
}

export function validateEmail(email: string) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
}

export function EmailHeader(){
    return '<div><a style="  font-size: 30px; font-weight: 900; font-family: helvetica, arial, fantasy; color: #33813B; padding: 5px 5px; background-image: linear-gradient(to bottom right, #33813B , rgb(255,255,255)) text-shadow: -1px -1px 1px #aaa, 0px 4px 1px rgba(191, 64, 191, 0.5), 4px 4px 5px rgba(191, 64, 191, 0.7), 0px 0px 7px rgba(191, 64, 191,0.4) text-decoration: none;" href="https://64cents.com/">64cents</a></div><br>';
}

export const getLine = (text: any, numberofchars: any) => {
    if(numberofchars>=text.length)
      return text;
  
    let words = text.split(' ');
    let usedchars = 0;
    let line = '';
    let idx = 0;
    while(usedchars< numberofchars && idx < words.length){
      line += words[idx] + " "
      usedchars = line.length;
      idx++;
    }
    return line;
  }


// export const hashStringSHA256 = (input: any) => {
//     const hash = crypto.createHash('sha256');
//     hash.update(input);
//     return hash.digest('hex');
// }

export const getFirstTuesdayOfMonth = (month: any, year: any) => {
    const firstDay = new Date(year, month - 1, 1);
    const dayOfWeek = firstDay.getDay();
    const daysUntilTuesday = (9 - dayOfWeek) % 7;
    
    const firstTuesday = new Date(year, month - 1, 1 + daysUntilTuesday);
  
    return formatDate(firstTuesday, "M/d/Y")
}

export const sleep = (msec: any) =>{
  return new Promise(async (resolve: any) => {
    await setTimeout(()=>{ resolve(true)}, msec)
  })
}
export const monthNames = [
  'january', 'february', 'march', 'april', 'may', 'june',
  'july', 'august', 'september', 'october', 'november', 'december'
]

export const shortMonthNames = [
  'jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'
]

export const getYearFromMonth = (monthidx: any) => {
  const currentYear = new Date().getFullYear()
  const currentMonth = new Date().getMonth() // Adding 1 because months are zero-based

  if (monthidx === -1) {
      // Invalid month name input
      return -1
  }

  if (monthidx < currentMonth) {
      // If the input month has already passed in the current year, return the current year
      return currentYear+1
  } else {
      // If the input month is in the future, return the next year
      return currentYear 
  }
}

// export const downloadPDF = async (url: string, outputfilepath: any) => {

//   try{  
//       const response = await fetch(url)
//       var pdfarray = await response.arrayBuffer()
//       var binpdf = Buffer.from(pdfarray)
//       fs.writeFileSync(outputfilepath, binpdf, 'binary')
//   }catch(error){
//       console.log(`here ${error}`)
//   }

// }
