import React, { useState, useEffect } from 'react'
import styled, { keyframes } from 'styled-components';
import styles from "./Button.module.css"


interface ContainerProps {
    backgroundColor?: string;
    width?: string;
}

interface Buttonprops {
    text: string;
    color?: any;
    handleClick?:(x?: any)=>void;
    backgroundColor?: any;
    width?:any;
}

const Button: React.FC<Buttonprops> = ({text, color, backgroundColor, handleClick, width}) => {
    const btnclick = (e:any) => {
        handleClick&&handleClick()
    }

    return (
        <MainContainer>
            <Container className={styles.ripple} style={{backgroundColor:backgroundColor?backgroundColor:"#33813B", width:width?width:'auto', userSelect:'none'}} onClick={btnclick} >
                {text}
            </Container>
        </MainContainer>
    )
}

const MainContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
`;
const Container = styled.div<ContainerProps>`
    margin: 5px;
    min-width: 100px;
    min-height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    text-transform: uppercase;
    padding: 15px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgba(100, 100, 100, 0.25);
    transition: ease 0.5s;
    color: #FFFFFF;
    overflow: clip;
    background-color: ${(props) => props.backgroundColor || '#33813B'};
    width: ${(props) => props.width || 'auto'};
    &:hover {
        box-shadow: 0 2px 4px 2px rgba(100, 100, 100, 1);
    }
`;


export default Button;