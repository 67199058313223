import styled, {keyframes} from "styled-components";

const Slidein = keyframes`
  0% {
    transform: translate(-50%, 100%)
  }
  100% {
    transform: translate(-50%, -50%)
  }
`;

export const StyledBackdrop = styled.div`
  background-color: #000;
  opacity: 0.75;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 500%;
  z-index: 999;
`;

export const StyledModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  background: #ffffff;
  border: 1px solid #33813B;
  border-radius: 10px;
  width: min(500px, 75%);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  max-height: 90vh;
  animation: ${Slidein} 0.5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
`;

export const HeaderContainer = styled.div`
  display: flex;
  padding: 20px;
  background-color: #eee;
  border-radius: 10px 10px 0 0;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid lightgray;
`;

export const Header = styled.div`
  display: flex;
  font-size: 25px;
  font-weight: 600;
  color: black;
`;

export const Body = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  line-height: 2;
  white-space: pre-wrap;
  font-size: 14px;
  font-weight: 500;
  color: black;
  margin: 3px;
  overflow-y: scroll;
  overflow-x : hidden;
  padding: 10px;
`;

export const RegisterField = styled.div`
  display: flex;
  margin: 10px 20px;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`;

export const ErrorMsg = styled.div`
  color: red;
  font-size: 16px;
  text-align: center;
  margin: 3px;
`;

export const CloseIcon = styled.div`
  margin: 5px;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  text-transform: uppercase;
  padding: 7px;
  font-weight: 500;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(100, 100, 100, 0.25);
  background-color: transparent;
  color: #c1c1c1;
  width: auto
  transition: ease 0.5s;
  &:hover {
      box-shadow: 0 2px 4px 2px rgba(100, 100, 100, 1);
  }
`;  

export const Message = styled.div`
  // overflow-y: scroll;
  overflow-x: hidden;
  max-height: 150px;
`;

export const SigCBSpan = styled.span`
  display: flex;
  align-items: center;
`;
