import React, { useState, useCallback } from 'react'
import LoadingComponent from 'components/LoadingComponent';
import Button from 'components/Button';
import { ImCross } from "react-icons/im"
import {
  StyledBackdrop,
  StyledModal,
  HeaderContainer,
  Header,
  Body,
  CloseIcon,
} from "./styles";

interface MessageModalI {
  header?: string;
  message?: string;
  state: boolean;
  handleClose(): void;
  progress?: boolean;
  bodyfont?: any;
  showconfirmmodal?:any;
  confirmmodalhandle?:()=>void;
}

const CustomModal: React.FC<MessageModalI> = ({
  header,
  message,
  state,
  handleClose,
  progress,
  bodyfont,
}) => {
  return (
    <>
      {state && (
        <>
          <StyledBackdrop />
          <StyledModal>
            <HeaderContainer>
              <Header>{header}</Header>
              <CloseIcon onClick={handleClose}><ImCross fontSize={"20px"} color={"#33813B"}/></CloseIcon>
            </HeaderContainer>
            <Body style={{fontSize:bodyfont?bodyfont:"14px"}}>
              {
                progress?<LoadingComponent /> :<></>
              }
              {message}
              <Button handleClick={handleClose} text="Close" />
            </Body>
          </StyledModal>
        </>
      )}
    </>
  )
}


export default CustomModal
