export const citycountymap = [
    {city:'Alamo Heights', county: "Bexar County"},
    {city:'Balcones Heights', county: "Bexar County"},
    {city:'Castle Hills', county: "Bexar County"},
    {city:'Cibolo', county: "Bexar County"},
    {city:'Converse', county: "Bexar County"},
    {city:'Elmendorf', county: "Bexar County"},
    {city:'Fair Oaks Ranch', county: "Bexar County"},
    {city:'Grey Forest', county: "Bexar County"},
    {city:'Helotes', county: "Bexar County"},
    {city:'Hill Country Village', county: "Bexar County"},
    {city:'Kirby', county: "Bexar County"},
    {city:'Leon Valley', county: "Bexar County"},
    {city:'Live Oak', county: "Bexar County"},
    {city:'Lytle', county: "Bexar County"},
    {city:'Martinez', county: "Bexar County"},
    {city:'Olmos Park', county: "Bexar County"},
    {city:'San Antonio', county: "Bexar County"},
    {city:'Sandy Oaks', county: "Bexar County"},
    {city:'Schertz', county: "Bexar County"},
    {city:'Selma', county: "Bexar County"},
    {city:'Shavano Park', county: "Bexar County"},
    {city:'Somerset', county: "Bexar County"},
    {city:'Terrell Hills', county: "Bexar County"},
    {city:'Universal City', county: "Bexar County"},
    {city:'Von Ormy', county: "Bexar County"},
    {city:'Windcrest', county: "Bexar County"},
    {city:'allen', county: "Collin County"},
    {city:'anna', county: "Collin County"},
    {city:'blue ridge', county: "Collin County"},
    {city:'carrollton', county: "Collin County"},
    {city:'celina', county: "Collin County"},
    {city:'copeville', county: "Collin County"},
    {city:'fairview', county: "Collin County"},
    {city:'farmersville', county: "Collin County"},
    {city:'frisco', county: "Collin County"},
    {city:'garland', county: "Collin County"},
    {city:'josephine', county: "Collin County"},
    {city:'lavon', county: "Collin County"},
    {city:'lowry crossing', county: "Collin County"},
    {city:'lucas', county: "Collin County"},
    {city:'mckinney', county: "Collin County"},
    {city:'melissa', county: "Collin County"},
    {city:'murphy', county: "Collin County"},
    {city:'nevada', county: "Collin County"},
    {city:'new hope', county: "Collin County"},
    {city:'parker', county: "Collin County"},
    {city:'plano', county: "Collin County"},
    {city:'princeton', county: "Collin County"},
    {city:'prosper', county: "Collin County"},
    {city:'richardson', county: "Collin County"},
    {city:'royse city', county: "Collin County"},
    {city:'sachse', county: "Collin County"},
    {city:'saint paul', county: "Collin County"},
    {city:'van alstyne', county: "Collin County"},
    {city:'weston', county: "Collin County"},
    {city:'wylie', county: "Collin County"},
    {city:'addison', county: "Dallas County"},
    {city:'balch springs', county: "Dallas County"},
    {city:'carrolton', county: "Dallas County"},
    {city:'cedar hill', county: "Dallas County"},
    {city:'cockrell hill', county: "Dallas County"},
    {city:'combine', county: "Dallas County"},
    {city:'coppell', county: "Dallas County"},
    {city:'dallas', county: "Dallas County"},
    {city:'desoto', county: "Dallas County"},
    {city:'duncanville', county: "Dallas County"},
    {city:'farmers branch', county: "Dallas County"},
    {city:'garland', county: "Dallas County"},
    {city:'grand prairie', county: "Dallas County"},
    {city:'glenn heights', county: "Dallas County"},
    {city:'highland park', county: "Dallas County"},
    {city:'hutchins', county: "Dallas County"},
    {city:'irving', county: "Dallas County"},
    {city:'lancaster', county: "Dallas County"},
    {city:'mesquite', county: "Dallas County"},
    {city:'richardson', county: "Dallas County"},
    {city:'rowlett', county: "Dallas County"},
    {city:'sachse', county: "Dallas County"},
    {city:'seagoville', county: "Dallas County"},
    {city:'sunnyvale', county: "Dallas County"},
    {city:'university park', county: "Dallas County"},
    {city:'wilmer', county: "Dallas County"},
    {city: "Houston", county: "Harris County"},
    {city: "Pasadena", county: "Harris County"},
    {city: "Pearland", county: "Harris County"},
    {city: "The Woodlands", county: "Harris County"},
    {city: "League City", county: "Harris County"},
    {city: "Atascocita", county: "Harris County"},
    {city: "Baytown", county: "Harris County"},
    {city: "Missouri City", county: "Harris County"},
    {city: "Spring", county: "Harris County"},
    {city: "Channelview", county: "Harris County"},
    {city: "Friendswood", county: "Harris County"},
    {city: "La Porte", county: "Harris County"},
    {city: "Mission Bend", county: "Harris County"},
    {city: "Deer Park", county: "Harris County"},
    {city: "Katy", county: "Harris County"},
    {city: "Cloverleaf", county: "Harris County"},
    {city: "Cinco Ranch", county: "Harris County"},
    {city: "Stafford", county: "Harris County"},
    {city: "Bellaire", county: "Harris County"},
    {city: "Humble", county: "Harris County"},
    {city: "South Houston", county: "Harris County"},
    {city: "Tomball", county: "Harris County"},
    {city: "Aldine", county: "Harris County"},
    {city: "West University Place", county: "Harris County"},
    {city: "Seabrook", county: "Harris County"},
    {city: "Webster", county: "Harris County"},
    {city: "Galena Park", county: "Harris County"},
    {city: "Jacinto City", county: "Harris County"},
    {city: "Jersey Village", county: "Harris County"},
    {city: "Highlands", county: "Harris County"},
    {city: "Nassau Bay", county: "Harris County"},
    {city: "Spring Valley Village", county: "Harris County"},
    {city: "Hunters Creek Village", county: "Harris County"},
    {city: "Bunker Hill Village", county: "Harris County"},
    {city: "Taylor Lake Village", county: "Harris County"},
    {city: "Barrett", county: "Harris County"},
    {city: "Waller", county: "Harris County"},
    {city: "Piney Point Village", county: "Harris County"},
    {city: "El Lago", county: "Harris County"},
    {city: "Sheldon", county: "Harris County"},
    {city: "Hedwig Village", county: "Harris County"},
    {city: "Southside Place", county: "Harris County"},
    {city: "Crosby", county: "Harris County"},
    {city: "Shoreacres", county: "Harris County"},
    {city: "Hilshire Village", county: "Harris County"},
    {city: "Morgan's Point", county: "Harris County"},
    {city: "Arlington", county: "Tarrant County"},
    {city: "Azle", county: "Tarrant County"},
    {city: "Bedford", county: "Tarrant County"},
    {city: "Benbrook", county: "Tarrant County"},
    {city: "Blue Mound", county: "Tarrant County"},
    {city: "Burleson", county: "Tarrant County"},
    {city: "Colleyville", county: "Tarrant County"},
    {city: "Crowley", county: "Tarrant County"},
    {city: "Dalworthington Gardens", county: "Tarrant County"},
    {city: "Edgecliff Village", county: "Tarrant County"},
    {city: "Euless", county: "Tarrant County"},
    {city: "Everman", county: "Tarrant County"},
    {city: "Flower Mound", county: "Tarrant County"},
    {city: "Forest Hill", county: "Tarrant County"},
    {city: "Fort Worth", county: "Tarrant County"},
    {city: "Grand Prairie", county: "Tarrant County"},
    {city: "Grapevine", county: "Tarrant County"},
    {city: "Haltom City", county: "Tarrant County"},
    {city: "Haslet", county: "Tarrant County"},
    {city: "Hurst", county: "Tarrant County"},
    {city: "Keller", county: "Tarrant County"},
    {city: "Kennedale", county: "Tarrant County"},
    {city: "Lakeside", county: "Tarrant County"},
    {city: "Lake Worth", county: "Tarrant County"},
    {city: "Mansfield", county: "Tarrant County"},
    {city: "Newark", county: "Tarrant County"},
    {city: "North Richland Hills", county: "Tarrant County"},
    {city: "Pantego", county: "Tarrant County"},
    {city: "Pelican Bay", county: "Tarrant County"},
    {city: "Reno", county: "Tarrant County"},
    {city: "Richland Hills", county: "Tarrant County"},
    {city: "River Oaks", county: "Tarrant County"},
    {city: "Saginaw", county: "Tarrant County"},
    {city: "Sansom Park", county: "Tarrant County"},
    {city: "Southlake", county: "Tarrant County"},
    {city: "Trophy Club", county: "Tarrant County"},
    {city: "Watauga", county: "Tarrant County"},
    {city: "Westlake", county: "Tarrant County"},
    {city: "Westover Hills", county: "Tarrant County"},
    {city: "Westworth Village", county: "Tarrant County"},
    {city: "White Settlement", county: "Tarrant County"},
]

export const getCounty = (city: any)=>{
    let x: any = citycountymap.find((x: any)=>x.city.toLowerCase()==city.toLowerCase())
    return x?x.county:"none"
}