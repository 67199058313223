import { BehaviorSubject } from 'rxjs';
import { fetchWrapper } from 'utils/apihandlers';

const ISSERVER = typeof window === "undefined";
const user = !ISSERVER && JSON.parse(localStorage.getItem('user')||'{}')
// const user = !ISSERVER && JSON.parse(localStorage.getItem('user')||{})
const userSubject = new BehaviorSubject(user)

export const userContext = {
    user: userSubject.asObservable(),
    get userValue () { return userSubject.value },
    logout,
    login
};

function logout() {
    // remove user from local storage, publish null to user subscribers and redirect to login page
    localStorage.removeItem('user')
    userSubject.next({})
}

async function login(email:any, password:any) {
    return new Promise(async (resolve) =>{
        let user = fetchWrapper.post(`/api/authenticate/login`, { email, password })
            .then(async (user: any) => {
                userSubject.next(user.data)
                localStorage.setItem('user', JSON.stringify(user.data))

                return user;
            }).catch((error: any) =>{
                console.log(error)
                
            })
        resolve(user)
    })
}
