import React, { useState, useCallback } from 'react'
import styled, { keyframes } from 'styled-components'
import LoadingComponent from 'components/LoadingComponent';
import { ImCross } from "react-icons/im"
import Button from 'components/Button';

interface MessageModalI {
  header?: string;
  message?: string;
  state: boolean;
  handleClose(): void;
  progress?: boolean;
  bodyfont?: any;
  showconfirmmodal?:any;
  confirmmodalhandle?:()=>void;
}

const MessageModal: React.FC<MessageModalI> = ({
  header,
  message,
  state,
  handleClose,
  progress,
  bodyfont,
}) => {
  return (
    <>
      {state && (
        <>
          <StyledBackdrop />
          <StyledModal>
            <HeaderContainer>
              <Header>{header}</Header>
              <CloseIcon onClick={handleClose}><ImCross fontSize={"20px"} color={"#0F2557"}/></CloseIcon>
            </HeaderContainer>
            <Body style={{fontSize:bodyfont?bodyfont:"14px"}}>
              {message}
              {
                progress?<LoadingComponent /> :<></>
              }
              <Button handleClick={handleClose} text="Close" />
            </Body>
          </StyledModal>
        </>
      )}
    </>
  )
}

const Slidein = keyframes`
  0% {
    transform: translate(-50%, 100%);
  }
  100% {
    transform: translate(-50%, -50%);
  }
`;

const StyledBackdrop = styled.div`
  background-color: #000;
  opacity: 0.95;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 1000%;
  z-index: 999;
`

const StyledModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  background: #ffffff;
  border-radius: 10px;
  width: min(500px, 75%);
  top: 50%;
  left: 50%;
  z-index: 1000;
  transform: translate(-50%, -50%);
  animation: ${Slidein} 0.5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
`

const HeaderContainer = styled.div`
  display: flex;
  padding: 20px;
  background-color: #eee;
  border-radius: 10px 10px 0 0;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid gray;
  color: black;
`

const Header = styled.div`
  display: flex;
  font-size: 25px;
  font-weight: 600;
  color: #0F2557;
`

const Body = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  line-height: 2;
  white-space: pre-wrap;
  font-size: 14px;
  font-weight: 500;
  color: black;
  margin: 10px;
  padding: 10px;
  text-align: center;
`
const CloseIcon = styled.div`
  margin: 5px;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  text-transform: uppercase;
  padding: 7px;
  font-weight: 500;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(100, 100, 100, 0.25);
  background-color: transparent;
  color: #c1c1c1;
  width: auto;
  transition: ease 0.5s;
  &:hover {
      box-shadow: 0 2px 4px 2px rgba(100, 100, 100, 1);
  }
`;  

export default MessageModal
